<template>
  <div class="d-flex flex-column py-6">
    <v-row justify="center">
      <v-col cols="12" md="10" lg="8" xl="6">
        <h1 class="text-h4 secondary--text font-weight-bold mb-3">
          <span >Recuperar senha</span>
        </h1>

        <div class="text-body-1 font-weight-bold mb-8">
          <span >
            Informe seu endereço de e-mail e siga as instruções de recuperação.
          </span>
        </div>

        <v-form v-model="valid" ref="form">
          <div @keypress.enter.prevent="submit()" class="mb-6">
            <custom-input
              v-model="form.email"
              label="E-mail"
              placeholder="Digite seu e-mail"
              outlined
            />
          </div>

          <v-btn
            class="mb-6 rounded-xl secondary--text"
            color="primary2"
            block
            large
            @click="registration()"
          >
            Solicite uma nova senha
          </v-btn>

          <div class="d-flex justify-center text-center">
            <router-link
              to="/auth/sign-in"
              class="text-decoration-none text-body-1"
            >
              <span >
                <span class="overtext--text font-weight-bold">
                  Lembrou da sua senha?
                </span>
                <span class="font-weight-bold secondary--text"> Entrar </span>
              </span>
            </router-link>
          </div>
        </v-form>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      valid: false,
      form: {
        email: "",
      },
    };
  },
};
</script>

<style></style>
